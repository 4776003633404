import React, { useState } from 'react';

// Data for each section (image, name, description)
const people = [
  {
    name: "Vaidhurya Sharma",
    image: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiM8xxcc-JPkYaDkaIG-HHtkLAMmjB_ICODIuPSt8-pg3VZdDQ247yil0GArhvqZWMcip4LsXhLZZybZWQgsZXjSjEZ66ILDXRmp34HdjCPxLG0g3rE3xLL6LwB-rSSzL_oyiW1KTfsKW9PDY-Zucle1MCEdMeuox-Ls8Y7-dW19NEHyw1qdjI5TxP6trpQ/s320/WhatsApp%20Image%202024-10-12%20at%2011.33.41.jpeg",
    description: "App Maker and Owner",
    fullDescription:
      "Vaidhurya Sharma is a software developer with 5 years of experience in building web applications.",
  },
  {
    name: "Ashish Vyas",
    image: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh8HF3MI20SnVNHN1uVo07V6LjhocPuXowxu0cGItvWxw_n8iNUw0PVKjZYzdMjwbcbyhCv3LX5RNRhHQOqw6jPmp4vH_iszpqqv9lcsHzYwkPS7Zb5Rf9NBCwwpu26XKXEemFaW_NcvYrSYDgrfEhD8Z3Bce5qJk0MX4th9_vZ1sZpoRXSbtcu5_Wq9Tg_/s320/1699611895261.jpg",
    description: "Designer and Typer",
    fullDescription:
      "Ashish Vyas is a software designer and typer who has worked with top brands worldwide.",
  },
  {
    name: "Shrija Sharma",
    image: "https://pics.craiyon.com/2023-06-04/50f169348eb24ce0919dba8133c08ddc.webp",
    description: "Invester",
    fullDescription:
      "Shrija Sharma is a project manager known for her effective communication and leadership skills.",
  },
];

const About = () => {
    const [isOpen, setIsOpen] = useState(false);
  // State to manage toggle for each person
  const [minimizedSections, setMinimizedSections] = useState(
    people.map(() => true) // Initially, all sections are minimized
  );

  // Function to toggle a specific section
  const toggleContent = (index) => {
    setMinimizedSections((prevState) =>
      prevState.map((isMinimized, i) =>
        i === index ? !isMinimized : isMinimized
      )
    );
  };

  return (
    <>
     <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0">
              <h1 className="text-white font-bold text-xl">BTTS Astrologers</h1>
            </div>
          
          </div>
          <div className="hidden sm:block sm:ml-6 items-end justify-end ">
              <div className="flex space-x-4">
                <a href="/" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Home
                </a>
                <a href="/prediction" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Prediction
                </a>
                <a href="/formula" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Formula
                </a>
                <a href="/about" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  About
                </a>
              </div>
            </div>
        </div>
      </div>

      <div className={`${isOpen ? 'block' : 'hidden'} sm:hidden`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1">
          <a href="/" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
            Home
          </a>
          <a href="/prediction" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
            Prediction
          </a>
          <a href="/formula" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
            Formula
          </a>
          <a href="/about" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
            About
          </a>
        </div>
      </div>
    </nav>
    <div className="sm:p-0 lg:p-4 md:p-2">
      {people.map((person, index) => (
        <div key={index} className="p-4 mb-4 sm:p-0 lg:p-4 md:p-2" onClick={() => toggleContent(index)}>
          {/* Circle Image and Name */}
          <div className="flex items-center">
            <img
              src={person.image}
              alt={person.name}
              className="w-12 h-12 rounded-full mr-4"
            />
            <div>
              <h2 className="text-lg font-bold">{person.name}</h2>
              <p className="text-sm text-gray-500">{person.description}</p>
            </div>

          </div>

          {/* Conditionally show/hide the full description */}
          {!minimizedSections[index] && (
            <div className="mt-4 text-gray-700 px-16">
              <p>{person.fullDescription}</p>
            </div>
          )}
        </div>
      ))}
    </div>
    </>
  );
};

export default About;
