import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import './App.css'; // Ensure you have the TailwindCSS setup in your project
import About from './About';
import Formula from './Formula';
import Prediction from './Prediction';
import Home from './Home';


const App = () => {
 

  return (
    <>
    <Routes>
        <Route path='/' element={<Home />} ></Route>
        <Route path='/about' element={<About />} ></Route>
        <Route path='/prediction' element={<Prediction />} ></Route>
        <Route path='/formula' element={<Formula />} ></Route>
    </Routes>
    </>
  );
}

export default App;
