import React, { useState } from 'react';

const Formula = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={() => setIsOpen(!isOpen)}
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0">
                <h1 className="text-white font-bold text-xl">BTTS Astrologers</h1>
              </div>
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                <a
                  href="/"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Home
                </a>
                <a
                  href="/prediction"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Prediction
                </a>
                <a
                  href="/formula"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  Formula
                </a>
                <a
                  href="/about"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                >
                  About
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={`${isOpen ? 'block' : 'hidden'} sm:hidden`} id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <a
              href="/"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Home
            </a>
            <a
              href="/prediction"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Prediction
            </a>
            <a
              href="/formula"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              Formula
            </a>
            <a
              href="/about"
              className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            >
              About
            </a>
          </div>
        </div>
      </nav>

      <div className="p-4 max-w-lg md:max-w-3xl lg:max-w-5xl mx-auto">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-4">
          नाडी ज्योतिष से फलित करना
        </h2>

        <p className="text-gray-700 text-base md:text-base lg:text-lg mb-4">
          ग्रह, नक्षत्र एवं भाव के सक्रियता से फलित करने के लिए कुछ फॉर्मूले दिए गये है:
        </p>

        <ol className="list-decimal list-inside">
        <br />
          <li className="text-gray-600 text-sm md:text-base lg:text-base mb-4">
            ग्रह के लिए आप देखे की ग्रह की किस भाव में बैठकर क्या प्रभाव पड रहा है। मतलब जिस भाव में ग्रह बैटा है तो उसमें समस्या देगा और यदि अच्छा है। तो उसका प्रभाव देगाा और कहा का स्वामी है। उसका भी प्रभाव देगा।
            <br /><br /> जैसे - केतु पंचम भाव में है तो केतु अच्छा ग्रह नही है तो शिक्षा को बिगाडेंगा जब केतु सक्रिय होगा।
            <br /><br /> दूसरा ऐक्सापल - यह है कि यदि पंचम भाव में सूर्य है और वो 10 भाव का स्वामी है तो उस ग्रह से रिलेटेड ऐज में नौकरी में सम्मान और वर्चस्व बढेगा।
          </li>
          <br /><br />
          <li className="text-gray-600 text-sm md:text-base lg:text-base mb-4">
            भाव में यह देखना है कि उसमें कौनसा ग्रह बैठा है। उसका प्रभाव देखा जैसा उस भाव में उस ग्रह का क्या प्रभाव है वा रिजल्ट आायेगा। और वो ग्रह कहा का स्वामी है। उसका रिजल्ट भी आयेगा।
            <br /><br /> जैसे - केतु यदि पंचम भाव में है तो केतु बुरा ग्रह है और वो पंचम भाव मेें है तो शिक्षा में डरोप करेगा 1 साल वो भी 5 भाव 16 ऐज को ऐक्टिवेट करता है। लगभग कुण्डलियों में ऐसे ही उस वक्त डरोप होगा।
            <br /><br /> दूसरा ऐक्सापल - यह है कि यदि पंचम भाव में सूर्य है और वो 10 भाव का स्वामी है तो उस भाव से रिलेटेड ऐज में नौकरी लगेगी पर ऐसे सवाल वर्गा चार्ट में ही देखे।
          </li>
          <br /><br />
          <li className="text-gray-600 text-sm md:text-base lg:text-base mb-4">
            ऐसे ही नक्षत्र को देखे भाव और ग्रह की तरह
          </li>
        </ol>
      </div>
    </>
  );
};

export default Formula;
