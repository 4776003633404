import React, { useState, useEffect } from 'react';

export default function Prediction() {
    const [isOpen, setIsOpen] = useState(false);
    const [planetData, setPlanetData] = useState([]); // Initial state for planetData
    const [age, setAge] = useState('');

    const [planetage, setPlanetage] = useState([
      { name: 'Surya', activationAge: [22, 55], phal: 'पिता, स्वास्थ्य, शक्ति, राजा, राजसी सहयोग, उच्च पद, प्रभुत्व, गर्मी, ग्रीष्म, औषधि, पर्वत, जंगल, दायी आख, राज्य अधिकारी।' },
      { name: 'Chandra', activationAge: [23, 24, 56, 57], phal: 'माता, दिमाग, मन, मूल, भावनाए, सुन्दरता, भद्रता, जलीय उत्पाद, जलीय - जन्तु तथा जल से सम्बन्धित कार्य, तरल पदार्थ, दूध, इत्र।' },
      { name: 'Mangal', activationAge: [28, 29, 30, 31, 32, 33, 61, 62, 63, 64, 65, 66], phal: 'साहस, शारीरिक शक्ति, सहोदर अनुज, जोखिम भरा साहसिक कार्य, पुलिस, सेना, प्रशासक, शल्य - चिकित्सा, अनन्यगमन, प्रसिद्धि, इंजीनियर, भूमि, अग्नि की खदानें, हथियारों के सौदागर।' },
      { name: 'Budh', activationAge: [34, 35, 67, 68], phal: 'सीखने की सभी शाखाएं, व्यापार, मित्रख् वाणी, हेमन्त, ऋतु, मामा व मामी, सीखने के साधन, बिजली के उपकरण।' },
      { name: 'Guru', activationAge: [16, 17, 18, 19, 20, 21, 50, 51, 52, 53, 54], phal: 'ज्ञान, प्रसन्नता, पढाई' },
      { name: 'Shukra', activationAge: [25, 26, 27, 58, 59, 60], phal: 'पत्नी, युवा, सुन्दरता, वाहन, यौन-सुख, धन-सम्पदा, फूल, खुशबू, इत्र।' },
      { name: 'Shani', activationAge: [1, 2, 3, 4, 5, 6, 36, 37, 38, 39, 40, 41, 69, 70, 71, 72, 73, 74], phal: 'दीर्घायु, दुख, कंजूस, वृद्ध, मृत्यु, गरीबी,सन्यास, दूरवर्ती स्थान, मिथ्यावादिता, विदेशी, पाप' },
      { name: 'Rahu', activationAge: [7, 8, 9, 10, 11, 12, 42, 43, 44, 45, 46, 75, 76, 77, 78, 79], phal: 'अचानक घटनाए, कठोर भाषा, मिथ्यावादिता, पाप, विदेेश यात्रा तथा निवास, सर्प, जुआ, जहर, तकनीकी शिक्षा, शरद ऋतु, जंगलो में घूमना।' },
      { name: 'Ketu', activationAge: [13, 14, 15, 47, 48, 49, 80, 81, 82] , phal: 'मोक्ष, मन्त्र-तन्त्र का ज्ञान, दर्शन-शास्त्र, गुप्त विद्याएं, तीर्थ-यात्रा, शल्य-क्रिया, कारावास, जादू-टोना, औषधि, आध्यात्मिक, पूर्वज, भूख।'},
    ]);

    const [houseage, setHouseage] = useState([
      { name: 'Lagna', activationAge: [1, 24, 35, 46, 56, 68, 79, 90], phal: 'इस भाव को लग्न कहा जाता है और यह स्वभाव और शरीर से जुड़ा होता है.' },
      { name: 'Second', activationAge: [2, 13, 36, 47, 58, 69, 80, 91], phal: 'यह धन, परिवार, वाणी, और नेत्रों से जुड़ा होता है.' },
      { name: 'Third', activationAge: [3, 14, 25, 48, 59, 70, 81, 92], phal: 'यह पराक्रम, साहस, और भाई-बहन से जुड़ा होता है.' },
      { name: 'Forth', activationAge: [4, 15, 26, 37, 60, 71, 82, 93], phal: 'यह सुख, वाहन, भूमि, माता, और घर से जुड़ा होता है.' },
      { name: 'Fifth', activationAge: [5, 16, 27, 38, 49, 72, 83, 94], phal: 'यह संतान, बुद्धि, और विद्या से जुड़ा होता है.' },
      { name: 'Sixth', activationAge: [6, 17, 28, 39, 50, 61, 84, 95], phal: 'यह रोग, शत्रु, और ऋण से जुड़ा होता है.' },
      { name: 'Seven', activationAge: [7, 18, 29, 40, 51, 62, 73, 96], phal: 'यह विवाह, जीवनसाथी, और पार्टनर से जुड़ा होता है.' },
      { name: 'Eight', activationAge: [8, 19, 30, 41, 52, 63, 74, 85], phal: 'यह आयु, खतरा, और दुर्घटना से जुड़ा होता है.' },
      { name: 'Ninth', activationAge: [9, 20, 31, 42, 52, 64, 75, 86], phal: 'यह भाग्य, पिता, गुरु, और धर्म से जुड़ा होता है.' },
      { name: 'Tenth', activationAge: [10, 21, 32, 43, 53, 65, 76, 87], phal: 'यह कर्म, व्यवसाय, पद, और ख्याति से जुड़ा होता है. '},
      { name: 'Eleventh', activationAge: [11, 22, 33, 44, 54, 66, 77, 88], phal: 'यह लाभ, अभिलाषा पूर्ति, और आयु से जुड़ा होता है.' },
      { name: 'Twelfth', activationAge: [12, 23, 34, 45, 55, 67, 78, 89], phal: 'यह खर्चा, नुकसान, और मोक्ष से जुड़ा होता है. ' },
    ]);

    const [nakage, setNakage] = useState([
      { name: 'Ashwini', activationAge: [1,2,3,5,10,20,24,28,30,46], phal: 'तेज़ गति, उपचार क्षमता, नेतृत्व, नई शुरुआत' },
      { name: 'Bharani', activationAge: [7,8,24,28,33,51], phal: 'सहनशक्ति, जीवन और मृत्यु, परिवर्तन, अनुशासन' },
      { name: 'Krittika', activationAge: [7,14,21,30,42,43,47,79], phal: 'अग्नि, काटना, आलोचना, शुद्धिकरण, दृढ़ता' },
      { name: 'Rohini', activationAge: [3,11,24,29,36,63,72,83,84], phal: 'विकास, सुंदरता, प्रेम, संपन्नता, सृजन' },
      { name: 'Mrigashira', activationAge: [4,8,17,25,34,46,50,65,73,79], phal: 'खोज, जिज्ञासा, आनंद, निश्चयहीनता, द्विधा' },
      { name: 'Ardra', activationAge: [15,18,24,37,42,61], phal: 'संवेदनशीलता, आंसू, तूफान, विनाश और पुनर्निर्माण' },
      { name: 'Punarvasu', activationAge: [30,31,39,40,66,70,84,86,89], phal: 'पुनर्निर्माण, वापसी, समृद्धि, शांति और संतोष' },
      { name: 'Pushya', activationAge: [16,24,33], phal: 'पालन-पोषण, धर्म, विकास, समर्पण और सुरक्षा' },
      { name: 'Ashlesha', activationAge: [14,17,27,30,33,41,65], phal: 'छल, रहस्य, जहर, अनुबंध और नियंत्रण' },
      { name: 'Magha', activationAge: [18,31,46,49,51,52,60,61,70,89], phal: 'विरासत, पूर्वजों का सम्मान, नेतृत्व और शक्ति' },
      { name: 'Purva Phalguni', activationAge: [5,24,29,37], phal: 'आनंद, विवाह, आराम, शांति और आराम' },
      { name: 'Uttara Phalguni', activationAge: [9,18,35,45,62,70,79,89], phal: 'संवेदनशीलता, मित्रता, स्थिरता और धैर्य' },
      { name: 'Hasta', activationAge: [3,6,22,24,46,50,68,77,79], phal: 'हाथों का कौशल, हेरफेर, हँसी-मज़ाक और शक्ति' },
      { name: 'Chitra', activationAge: [5,20,32,49,69,78,79], phal: 'सृजन, कला, सौंदर्य, कल्पना और विस्तार' },
      { name: 'Swati', activationAge: [3,20,30,37,39,41,45,55,84], phal: 'स्वतंत्रता, लचीला, हवा, व्यापार और संतुलन' },
      { name: 'Vishakha', activationAge: [5,33,37,39,43,48,49,57,60,66,82,89], phal: 'धैर्य, संकल्प, सफलता, सौहार्द और अनुशासन' },
      { name: 'Anuradha', activationAge: [25,26,29,27,37,44,48,63,66,78,84], phal: 'मित्रता, वफादारी, अनुशासन और सफलता' },
      { name: 'Jyeshtha', activationAge: [6,7,8,24,27,28,36], phal: 'सत्ता, अधिकार, सुरक्षा, रहस्य और संघर्ष' },
      { name: 'Moola', activationAge: [6,7,25,30,35,34,36,60,64,81,87], phal: 'मूल, विनाश, कष्ट, पुनर्निर्माण और सत्य की खोज' },
      { name: 'Purva Ashadha', activationAge: [8,12,16,24,30,64,72,77,88], phal: 'प्रसिद्धि, विजय, प्रेरणा और वर्चस्व' },
      { name: 'Uttara Ashadha', activationAge: [5,12,24,36,44,48,59,61,67,72,77,83], phal: 'धर्म, स्थायित्व, नेतृत्व, जीत और प्रतिष्ठा' },
      { name: 'Shravana', activationAge: [5,15,33,35,41,50,55,57,58,77], phal: 'श्रवण, सत्य का ज्ञान, अनुसंधान, बुद्धि और समर्पण' },
      { name: 'Dhanishta', activationAge: [27,36,40,39,45], phal: 'धन, संगीत, तालमेल, सामंजस्य और प्रतिभा' },
      { name: 'Shatabhisha', activationAge: [24,28,35,42,51,53,64,65,67,68,75,81], phal: 'उपचार, रहस्य, स्वतंत्रता, और आरोग्यता' },
      { name: 'Purva Bhadrapada', activationAge: [18,21,24,37,39,45,61,63,65,69,87], phal: 'तपस्या, संघर्ष, आध्यात्मिकता, बलिदान और कष्ट' },
      { name: 'Uttara Bhadrapada', activationAge: [11,18,19,24,64,68,70,75,76], phal: 'स्थायित्व, उन्नति, नियंत्रण, शांति और संतोष' },
      { name: 'Revati', activationAge: [5,6,12,32,42,60], phal: 'दया, सुरक्षा, समृद्धि, पालन-पोषण और देखभाल' },
    ]);
    

    // Filter based on activation age
    const filteredPlanets = planetage.filter(planet =>
        age === '' || planet.activationAge.includes(parseInt(age))
    );

    const filteredHouses = houseage.filter(house =>
        age === '' || house.activationAge.includes(parseInt(age))
    );

    const filteredNak = nakage.filter(nakshatra =>
      age === '' || nakshatra.activationAge.includes(parseInt(age))
  );



    return (
      <>
      <nav className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={() => setIsOpen(!isOpen)}
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0">
                <h1 className="text-white font-bold text-xl">BTTS Astrologers</h1>
              </div>
            </div>
            <div className="hidden sm:block sm:ml-6 items-end justify-end ">
              <div className="flex space-x-4">
                <a href="/" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Home
                </a>
                <a href="/prediction" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Prediction
                </a>
                <a href="/formula" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  Formula
                </a>
                <a href="/about" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">
                  About
                </a>
              </div>
            </div>
          </div>
        </div>
    
        <div className={`${isOpen ? 'block' : 'hidden'} sm:hidden`} id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <a href="/" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
              Home
            </a>
            <a href="/prediction" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
              Prediction
            </a>
            <a href="/formula" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
              Formula
            </a>
            <a href="/about" className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
              About
            </a>
          </div>
        </div>
      </nav>
    
      <div>
        <div className="p-4">
          <input
            type="number"
            placeholder="Enter Age"
            value={age}
            onChange={e => setAge(e.target.value)}
            className="p-2 border border-gray-300 rounded mb-4 w-full"
          />
    
          {/* Planets Table */}
          <div className="border border-gray-300 rounded mb-6">
            <div className="flex justify-between bg-gray-200 p-2">
              <span className="font-bold">Planet</span>
              <span className="font-bold">Activation Age</span>
            </div>
            {filteredPlanets.length > 0 ? (
              filteredPlanets.map((item) => (
                <div
                  key={item.name}
                  className="flex flex-col sm:flex-row justify-between p-2 border-t"
                >
                  <span>{item.name}</span>
                  <span>{item.phal}</span>
                  <span hidden>{item.activationAge.join(', ')}</span>
                </div>
              ))
            ) : (
              <div className="p-2">No matching planets found.</div>
            )}
          </div>
    
          {/* Houses Table */}
          <div className="border border-gray-300 rounded mb-6">
            <div className="flex justify-between bg-gray-200 p-2">
              <span className="font-bold">House</span>
              <span className="font-bold">Activation Age</span>
            </div>
            {filteredHouses.length > 0 ? (
              filteredHouses.map((item) => (
                <div
                  key={item.name}
                  className="flex flex-col sm:flex-row justify-between p-2 border-t"
                >
                  <span>{item.name}</span>
                  <span>{item.phal}</span>
                  <span hidden>{item.activationAge.join(', ')}</span>
                </div>
              ))
            ) : (
              <div className="p-2">No matching houses found.</div>
            )}
          </div>
    
          {/* Nakshatra Table */}
          <div className="border border-gray-300 rounded mb-6">
            <div className="flex justify-between bg-gray-200 p-2">
              <span className="font-bold">Nakshatra</span>
              <span className="font-bold">Activation Age</span>
            </div>
            {filteredNak.length > 0 ? (
              filteredNak.map((item) => (
                <div
                  key={item.name}
                  className="flex flex-col sm:flex-row justify-between p-2 border-t"
                >
                  <span>{item.name}</span>
                  <span>{item.phal}</span>
                  <span hidden>{item.activationAge.join(', ')}</span>
                </div>
              ))
            ) : (
              <div className="p-2">No matching Nakshatra found.</div>
            )}
          </div>
        </div>
      </div>
    </>
    
    );
}
